import React from "react";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import { Link as LinkTo } from 'react-router-dom';
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Education from "./Education";
import Projects from "./Projects";
import Skills from "./Skills";
import Work from "./Work";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

class Section extends React.Component {
  componentDidMount() {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve();
        Events.scrollEvent.remove("end");
      });

      scroller.scrollTo("scroll-container", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo("scroll-container-second-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scroll-container",
      })
    );
  }
  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  myFunction() {
    let element = document.body;
    element.classList.toggle("dark-mode");
  }

  render() {
    return (
      <div style={{overflowY:"scroll"}}>
        <Navbar
          bg="light"
          expand="lg"
          className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow navbar-padding bg-white rounded"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="h5 ml-auto mr-auto">
              <Link
                className="nav-item nav-link"
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                duration={500}
              >
                Home
              </Link>
              <Link
                className="nav-item nav-link"
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                duration={500}
              >
                About
              </Link>
              <Link
                className="nav-item nav-link"
                activeClass="active"
                to="education"
                spy={true}
                smooth={true}
                duration={500}
              >
                Education
              </Link>
              <Link
                className="nav-item nav-link"
                activeClass="active"
                to="experience"
                spy={true}
                smooth={true}
                duration={500}
              >
                Experience
              </Link>
              <Link
                className="nav-item nav-link"
                activeClass="active"
                to="skills"
                spy={true}
                smooth={true}
                duration={500}
              >
                Skills
              </Link>
              <Link
                className="nav-item nav-link"
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                duration={500}
              >
                Projects
              </Link>
              <Link
                className="nav-item nav-link"
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                duration={500}
              >
                Contact
              </Link>
              <a
                className="nav-item nav-link"
                activeClass="active"
                href="http://careonclick.paraspatel.ca/"
                spy={true}
                smooth={true}
                duration={500}
              >
                Care On Click
              </a>
            </Nav>
          </Navbar.Collapse>
          <i
            class="bi bi-brightness-high-fill mr-3 text-dark h4 theme "
            onClick={this.myFunction}
          ></i>
        </Navbar>
        <Element name="home" className="element">
          <Home></Home>
        </Element>

        <Element name="about" className="element">
          <br></br>
          <br></br>
          &nbsp;
          <About />
        </Element>
        <Element name="education" className="element">
          <br></br>
          <br></br>
          &nbsp;
          <Education />
        </Element>
        <Element name="experience" className="element">
          <br></br>
          <br></br>
          &nbsp;
          <Work />
        </Element>
        <Element name="skills" className="element">
          <br></br>
          <br></br>
          &nbsp;
          <Skills />
        </Element>
        <Element name="projects" className="element">
          <br></br>
          <br></br>
          &nbsp;
          <Projects />
        </Element>
        <Element name="contact" className="element">
          <br></br>
          <br></br>
          &nbsp;
          <Contact />
        </Element>
      </div>
    );
  }
}

export default Section;
