import Profile from "../images/menew.jpg";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "../../node_modules/aos/dist/aos.js";
import "../../node_modules/aos/dist/aos.css";
import AOS from "aos";
import Resume from "../Resume/ParasPatel.pdf";


const About = () => {
  AOS.init();
  function DisRes(){

  document.getElementById('displayResume').innerHTML= `
  <iframe
            src ={Resume}
            width="840 "
            height="680 "
            data-aos="zoom-in-up"
            data-aos-delay="300"
            class="aos-init"
          ></iframe>`;

  }
  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="1000"
      // className="abt-container"
    >
      {/* ------------------------------------------------------------------ */}
      <h3 className="text-center heading-responsive font-weight-bold h1">
        About
      </h3>
      <br></br>
      <br></br>
      <div className="container flex-column">
        <div className="row">
          {/* col-1*/}
          <div className="col-lg blockquote text-justify mt-4 mb-2">
            <p>
            As a dedicated and motivated individual with a passion for technology,
             I am committed to utilizing my skills and knowledge to contribute to a dynamic 
             and innovative team. With a strong foundation in computer science and a proven track 
             record of success in various roles, I am eager to take on new challenges and continue 
             to grow both personally and professionally.
            </p>
            <div>
              <table>
                <tbody>
                  <tr>
                    <th className="h5 w-50">
                      <i className="bi bi-file-person-fill"></i> Name:{" "}
                    </th>
                    <td>Paras Patel</td>
                  </tr>
                  <tr>
                    <th className="h5 w-50">
                      <i className="bi bi-envelope-fill"></i> Email:{" "}
                    </th>
                    <td>patelpasu110@gmail.com</td>
                  </tr>
                  <tr>
                    <th className="h5 w-50">
                      <i className="bi bi-telephone-inbound"></i> Phone:{" "}
                    </th>
                    <td>(647)-446-3591</td>
                  </tr>
                  <tr>
                    <th className=" h5 w-50">
                      <i className="bi bi-geo-alt-fill"></i> Location:{" "}
                    </th>
                    <td>Toronto, ON</td>
                  </tr>
                  <tr>
                    <th className="h5 w-50">
                      <i className="bi bi-code-slash"></i> Degree:{" "}
                    </th>
                    <td>Bachelor of Engineering in IT Computer Diploma</td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <a href={Resume} download>
                <button type="button" className="btn btn-primary btn"  >
                {/* onClick={DisRes} */}
                  Download Resume
                </button>
              </a>
              <div id="displayResume"></div>

            </div>
          </div>
   
          {/* col-2 */}
          <div className="col-lg mt-4 mb-2">
            <img
              src={Profile}
              alt="profile"
              className="rounded mx-auto d-block img-fluid"
            ></img>
          </div>
               </div>
      </div>

      {/* ------------------------------------------------------------------ */}
    </div>
  );
};

export default About;
