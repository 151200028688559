import "./App.css";

import NavigationBar from "./components/NavigationBar";
import Blogs from "./pages/blogs";
import { BrowserRouter, Routes, Route  } from "react-router-dom";

// import Work from "./components/Work";
import Education from "./components/Education";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NavigationBar />} >
        </Route>
          <Route path="/careonclick" element={<Blogs />} />
          <Route path="/*" element={<NavigationBar />} />
        
      </Routes>
    </BrowserRouter>
    // <div>
    //   <NavigationBar />
    // </div>
  );
}

export default App;
