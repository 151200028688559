import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/aos/dist/aos.js";
import "../../node_modules/aos/dist/aos.css";
import AOS from "aos";

const Work = () => {
  AOS.init();
  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="1000"
      
    >
      <h2 className="text-center heading-responsive font-weight-bold h1">
        Experience
      </h2>
      <br></br>
      <br></br>
      <div className="text-center width-component ml-auto mr-auto">
      <div>
          <p className="text-muted font-weight-bold text-left h4">
          Nov 2022 - apr 2023
          </p>
          <h2 className="text-left font-weight-bold h3">
          Lead Volunteer
          </h2>

          <h3 className="text-muted h4 text-left">
          BAPS Swaminarayan Akshardham, Robbinsville, New Jersey {" "}
            <a
              href="https://goo.gl/maps/xtCafiVEgpq7LzxeA"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-geo-alt-fill text-dark"></i>
            </a>
          </h3>

          <div className="blockquote text-left ">
            <ul>
            <li>
            Managed stone inventory and oversaw a team of 9 volunteers in locating and 
            distributing thousands of pre-carved stones to the mason team for construction projects.
              </li>
              <li>
              Demonstrated leadership and problem-solving skills by coordinating urgent requests and 
              ensuring accurate delivery of stones to the construction site.
              </li>
              <li>
              Learned valuable life lessons in loyalty, spirituality, unity, humility, integrity, 
              selflessness, determination, and generosity.
              </li>

            </ul>
          </div>
        </div>
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
        <div>
          <p className="text-muted font-weight-bold text-left h4">
          Jul 2021 - Nov 2022
          </p>
          <h2 className="text-left font-weight-bold h3">
          Telecommunications Technician
          </h2>

          <h3 className="text-muted h4 text-left">
          Communications Test Design Inc., Mississauga, Ontario {" "}
            <a
              href="https://goo.gl/maps/g46PGkBLQW9sfhcC8"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-geo-alt-fill text-dark"></i>
            </a>
          </h3>

          <div className="blockquote text-left ">
            <ul>
            <li>
            Installed and maintained telecommunications equipment, including routers, switches, and cabling systems, 
            ensuring efficient and reliable service for customers.
              </li>
              <li>
              Diagnosed and resolved network connectivity issues using various tools and techniques,
               such as ping and traceroute.
              </li>
              <li>
              Conducted regular maintenance checks and upgrades to ensure system integrity and security.
              </li>
              <li>
              Successfully completed projects involving the installation of large-scale telecommunications systems for government and enterprise clients, resulting in increased customer satisfaction and repeat business.
              </li>

            </ul>
          </div>
        </div>
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
        <div>
          <p className="text-muted font-weight-bold text-left h4">
          Jun 2020 - Apr 2021
          </p>
          <h2 className="text-left font-weight-bold h3">
          Peer Tutor
          </h2>

          <h3 className="text-muted h4 text-left">
          Sheridan College, Brampton, Ontario {" "}
            <a
              href="https://goo.gl/maps/N7xora4DeBgTXhuG9"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-geo-alt-fill text-dark"></i>
            </a>
          </h3>

          <div className="blockquote text-left ">
            <ul>
            <li>
            Tutored and mentored fellow students in various computer science courses, including data structures, programming, and software development.
            </li>
              <li>
              Conducted individual and group tutoring sessions, facilitating learning through clear explanations, demonstrations, and practice exercises.
              </li>
              <li>
              Communicated with course instructors and program coordinators to identify common areas of difficulty and provide feedback on student progress.
              </li>

            </ul>
          </div>
        </div>
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
      <div>
          <p className="text-muted font-weight-bold text-left h4">
          Feb 2020 - May 2020
          </p>
          <h2 className="text-left font-weight-bold h3">
          IT Help Desk Technician
          </h2>

          <h3 className="text-muted h4 text-left">
          Sheridan College, Brampton, Ontario {" "}
            <a
              href="https://goo.gl/maps/N7xora4DeBgTXhuG9"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-geo-alt-fill text-dark"></i>
            </a>
          </h3>

          <div className="blockquote text-left ">
            <ul>
            <li>
            Responded to in-person and remote IT support requests, troubleshooting, and resolving technical issues related to hardware, software, and network connectivity.
              </li>
              <li>
              Provided end-user support for over 300 students, faculty, and staff, demonstrating strong customer service skills and patience in resolving complex issues.
              </li>
              <li>
              Maintained and updated computer inventory and asset tracking system, ensuring accurate and up-to-date information for all equipment.
              </li>

            </ul>
          </div>
        </div>
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
        
        <div>
          <p className="text-muted font-weight-bold text-left h4">
          Aug 2018 - Jun 2019
          </p>
          <h2 className="text-left font-weight-bold h3">
          Junior Software Developer
          </h2>

          <h3 className="text-muted h4 text-left">
          Revo Karm Hub, Vadodara, Gujarat{" "}
            <a
              href="https://goo.gl/maps/MniPX3X6VfLdUeMP6"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-geo-alt-fill text-dark"></i>
            </a>
          </h3>

          <div className="blockquote text-left ">
            <ul>
            <li>
          	Worked on various projects under the supervision of senior developers, assisting in the development and implementation of software solutions.
              </li>
              <li>
             	Collaborated with cross-functional teams to design, code, and test software features and applications
              </li>
              <li>
             	Gained experience in programming languages such as Java, C#, and JavaScript, as well as in database management systems.
              </li>
              <li>
            	Debugged and troubleshooted software issues, improving code quality and functionality.
              </li>

            </ul>
          </div>
        </div>
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
        <div>
          <p className="text-muted font-weight-bold text-left h4">
          Jan 2018 - Jul 2018
          </p>
          <h2 className="text-left font-weight-bold h3">
          Software Engineer Intern
          </h2>

          <h3 className="text-muted h4 text-left">
          Webmyne Systems Private Limited, Vadodara, Gujarat{" "}
            <a
              href="https://goo.gl/maps/LR4F3vRmYNSJpirg6"
               target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-geo-alt-fill text-dark"></i>
            </a>
          </h3>

          <div className="blockquote text-left">
            <ul>
            <li>
            Collaborated with senior developers to design and develop mobile applications using Java and Android Studio.
              </li>
              
              <li>
              Participated in testing, debugging, and maintenance of applications to ensure smooth functionality.
              </li>
              <li>
              Communicated progress and roadblocks to the team and supervisor through daily stand-up meetings and weekly reports.
              </li>
              <li>
              Learned valuable skills in agile software development methodologies, and code review.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
