import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import Img11 from "../images/ttt.PNG";
import Img21 from "../images/p21.PNG";
import Img22 from "../images/p22.PNG";
import Img23 from "../images/p23.PNG";
import Img24 from "../images/p24.PNG";
import Img31 from "../images/p31.PNG";
import Img32 from "../images/p32.PNG";
import Img33 from "../images/p33.PNG";
import Img41 from "../images/p41.PNG";
import Wpf1 from "../images/wpf1.PNG";
import Wpf2 from "../images/wpf2.PNG";
import Wpf3 from "../images/wpf3.PNG";
import Wpf4 from "../images/wpf4.PNG";

import Careonclick from "../images/CareOnClick.png";
import "../../node_modules/aos/dist/aos.js";
import "../../node_modules/aos/dist/aos.css";
import AOS from "aos";

const Projects = () => {
  AOS.init();
  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="1000"
    >
      <h2 className="text-center  heading-responsive font-weight-bold h1">
        Projects
      </h2>
      <br></br>
      <br></br>
      <div className="text-center width-component1 mr-auto ml-auto special-images">
      <div className="row">
          <div className="col-lg text-left">
            <h2 className="font-weight-bold">Care On Click</h2>

            <div className="blockquote text-justify justify-content-between list-proper">
            CareOnClick is a leading provider of digital health solutions that empower patients and care teams with the tools they need to manage chronic conditions and improve health outcomes. Our platform integrates with electronic health records and other health IT systems to provide a seamless, user-friendly experience for patients and providers alike.
            </div>

            <p className="h5 text-dark">
              <i className="bi bi-link-45deg"></i>{" "}
              <a
                href="http://careonclick.paraspatel.ca/"
                target="_blank"
                className="text-decoration-none text-dark"
                rel="noreferrer"
              >
                CareOnClick
              </a>
            </p>
            <p className="blockquote text-justify justify-content-between list-proper">
              <p>
                <strong>Programming Language Used:</strong> React, Node.js, Java (SpringBoot) 
              </p>
              <p>
                <strong>Software’s Used:</strong> Visual Studio Code
              </p>
            </p>
          </div>
          <div className="col-lg">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Careonclick}
                    alt="First slide"
                  ></img>
                </div>
             
            </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
              rel="noreferrer"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------ */}
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
        <div className="row">
          <div className="col-lg text-left">
            <h2 className="font-weight-bold">Discussion Portal</h2>

            <div className="blockquote text-justify justify-content-between list-proper">
            Created an internal discussion portal for a company using Java Spring Boot and related technologies, enabling employees to engage in chat on a wide range of topics.

Developed a secure and user-friendly discussion platform with features such as thread creation, admin controls, and H2 database integration using Java, Spring Boot, and Spring Security.

Utilized Java and Spring Boot to build an efficient and effective internal discussion forum with robust dependency injection, Lombok, Thymeleaf, and Eclipse development tools. </div>

            <p className="h5 text-dark">
              <i className="bi bi-link-45deg"></i>{" "}
              <a
                href="https://github.com/paparasj/DiscussionForum"
                target="_blank"
                className="text-decoration-none text-dark"
                rel="noreferrer"
              >
                Github
              </a>
            </p>
            <p className="blockquote text-justify justify-content-between list-proper">
              <p>
                <strong>Programming Language Used:</strong> Java (SpringBoot) 
              </p>
              <p>
                <strong>Software’s Used:</strong> Eclipse
              </p>
            </p>
          </div>
     
        </div>
        {/* ------------------------------------------------------------------------------------------------------ */}
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
        <div className="row">
          <div className="col-lg text-left">
            <h2 className="font-weight-bold">Employee Management</h2>

            <div className="blockquote text-justify justify-content-between list-proper">
            Designed and developed an efficient and user-friendly Employee Management System using Spring Boot and related technologies, enabling HR teams to easily manage employee data and operations.

Implemented features such as employee data storage, role-based access control, and email notifications, ensuring seamless and secure management of employee information and workflows with the power of Spring Boot.
The system also includes features such as authentication, authorization, and data validation to ensure data security and integrity.
            </div>

            <p className="h5 text-dark">
              <i className="bi bi-link-45deg"></i>{" "}
              <a
                href="https://github.com/paparasj/EMS-backend"
                target="_blank"
                className="text-decoration-none text-dark"
                rel="noreferrer"
              >
                Github
              </a>
            </p>
            <p className="blockquote text-justify justify-content-between list-proper">
              <p>
                <strong>Programming Language Used:</strong> Java (SpringBoot) 
              </p>
              <p>
                <strong>Software’s Used:</strong> Eclipse
              </p>
            </p>
          </div>
     
        </div>
        {/* ------------------------------------------------------------------------------------------------------ */}
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
      <div className="row">
          <div className="col-lg text-left">
            <h2 className="font-weight-bold">Player's CRUD Operation - WPF</h2>

            <div className="blockquote text-justify justify-content-between list-proper">
              It is a WPF desktop application. The applicaton has multiple windows that displays and manages information
              of players of different sports. The app maintains generic collections of these players. The app can read, add,
              update, and delete the data and use LINQ to fetch the data.
            </div>

            <p className="h5 text-dark">
              <i className="bi bi-link-45deg"></i>{" "}
              <a
                href="https://github.com/paparasj/WPFPlayerStatManagement"
                target="_blank"
                className="text-decoration-none text-dark"
                rel="noreferrer"
              >
                Github
              </a>
            </p>
            <p className="blockquote text-justify justify-content-between list-proper">
              <p>
                <strong>Programming Language Used:</strong> C#
              </p>
              <p>
                <strong>Software’s Used:</strong> Visual Studio 2019
              </p>
            </p>
          </div>
          <div className="col-lg">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Wpf1}
                    alt="First slide"
                  ></img>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Wpf2}
                    alt="Second slide"
                  ></img>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Wpf3}
                    alt="Third slide"
                  ></img>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Wpf4}
                    alt="Fourth slide"
                  ></img>
                </div>
                
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
              rel="noreferrer"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------ */}
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
      <div className="row">
          <div className="col-lg text-left">
            <h2 className="font-weight-bold">Player's CRUD Operation - Console</h2>

            <div className="blockquote text-justify justify-content-between list-proper">
              It is a Console .NET core App. The application is menu based app to manage the player stats. There 
              are sub-menus to choose the player and different data from different games.
              It allows user to perform CRUD operation on players data. 
            </div>

            <p className="h5 text-dark">
              <i className="bi bi-link-45deg"></i>{" "}
              <a
                href="https://github.com/paparasj/ConsolePlayerStatManagement"
                target="_blank"
                className="text-decoration-none text-dark"
                rel="noreferrer"
              >
                Github
              </a>
            </p>
            <p className="blockquote text-justify justify-content-between list-proper">
              <p>
                <strong>Programming Language Used:</strong> C#
              </p>
              <p>
                <strong>Software’s Used:</strong> Visual Studio 2019
              </p>
            </p>
          </div>
          <div className="col-lg">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Img31}
                    alt="First slide"
                  ></img>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Img32}
                    alt="Second slide"
                  ></img>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Img33}
                    alt="Third slide"
                  ></img>
                </div>
                
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
              rel="noreferrer"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------ */}
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
       <div className="row">
          <div className="col-lg text-left">
            <h2 className="font-weight-bold">Game Shopping CRUD Operation</h2>

            <div className="blockquote text-justify justify-content-between list-proper">
              It is Console Application that was created using C# console.
              This console app is based on Entity Framework with DB First approach. Four CRUD operation includes purchasing Game,
              Displaying Customer's transaction history, viewing all transactions, and adding new stocks of games.
              
            </div>

            <p className="h5 text-dark">
              <i className="bi bi-link-45deg"></i>{" "}
              <a
                href="https://github.com/paparasj/FinalGameShoppingConcoleCRUD/"
                target="_blank"
                className="text-decoration-none text-dark"
                rel="noreferrer"
              >
                Github
              </a>
            </p>
            <p className="blockquote text-justify justify-content-between list-proper">
              <p>
                <strong>Programming Language Used:</strong> C#
              </p>
              <p>
                <strong>Software’s Used:</strong> Visual Studio 2019
              </p>
            </p>
          </div>
          <div className="col-lg">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Img21}
                    alt="First slide"
                  ></img>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Img22}
                    alt="Second slide"
                  ></img>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Img23}
                    alt="Third slide"
                  ></img>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 rounded mx-auto image-height"
                    src={Img24}
                    alt="Fourth slide"
                  ></img>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
              rel="noreferrer"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        {/* ----------------------------------------------------------------------------------------------- */}
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
        
        <div className="row">
          <div className="col-lg text-left">
            <h2 className="font-weight-bold">CSV Data To Oracle Database</h2>

            <div className="blockquote text-justify justify-content-between list-proper">
              This project helps to transfer the data from the donation list to
              Oracle Database Table. But while transferring it makes sure only
              valid data got transferred as it transfers the data of only valid
              addresses. The data with the wrong customer address got Rejected
              and stored in separate file to review. In addition, we also
              created the Star Schema and load the data from the address table
              into Star Schema.
            </div>
            <p className="blockquote text-justify justify-content-between list-proper">
              <p>
                <strong>Programming Language Used:</strong> SQL
              </p>
              <p>
                <strong>Software’s Used:</strong> Talend, Oracle SQL Developer
              </p>
            </p>
          </div>
          <div className="col-lg">
            <img
              className="d-block w-100 rounded mx-auto image-height"
              src={Img41}
              alt="First slide"
            ></img>
          </div>
        </div>
        &nbsp;
        <hr className="bg-dark"></hr>
        &nbsp;
        {/* --------------------------------------------------------------------------- */}
        <div className="row">
          <div className="col-lg text-left">
            <h2 className="font-weight-bold">Tic-Tac-Toe Reverse</h2>

            <div className="blockquote text-justify justify-content-between list-proper">
              This is a simple gaming website where two different players can
              play the reverse Tic Tac Toe using their own initials which will
              be taken through prompt. The result will be also displayed as
              alert. Successfully Deployed this App to cPanel.
            </div>

            <p className="h5">
              <i className="bi bi-link-45deg"></i>
              <a
                href="https://github.com/paparasj/Tic-Tac-Toe-Reverse"
                target="_blank"
                className="text-decoration-none  text-dark"
                rel="noreferrer"
              >
                {" "}
                Github
              </a>
            </p>

            
            <p className="blockquote text-justify justify-content-between list-proper">
              <p>
                <strong>Programming Language Used:</strong> HTML, CSS and
                JavaScript
              </p>
              <p>
                <strong>Software’s Used:</strong> Sublime Text 3
              </p>
            </p>
          </div>
          <div className="col-lg">
            <img
              className="d-block w-100 rounded mx-auto image-height"
              src={Img11}
              alt="First slide"
            ></img>
          </div>
        </div>
      
        {/* ------------------------------------------------------------------------------------------------------ */}
        
      </div>
      
</div>
  );
};

export default Projects;
