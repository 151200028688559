import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import anime from "../../node_modules/animejs";

const Home = () => {
  var ml4 = {};
  ml4.opacityIn = [0, 1];
  ml4.scaleIn = [0.2, 1];
  ml4.scaleOut = 3;
  ml4.durationIn = 800;
  ml4.durationOut = 600;
  ml4.delay = 500;

  anime
    .timeline({ loop: true })
    .add({
      targets: ".ml4 .letters-1",
      opacity: ml4.opacityIn,
      scale: ml4.scaleIn,
      duration: ml4.durationIn,
    })
    .add({
      targets: ".ml4 .letters-1",
      opacity: 0,
      scale: ml4.scaleOut,
      duration: ml4.durationOut,
      easing: "easeInExpo",
      delay: ml4.delay,
    })
    .add({
      targets: ".ml4 .letters-2",
      opacity: ml4.opacityIn,
      scale: ml4.scaleIn,
      duration: ml4.durationIn,
    })
    .add({
      targets: ".ml4 .letters-2",
      opacity: 0,
      scale: ml4.scaleOut,
      duration: ml4.durationOut,
      easing: "easeInExpo",
      delay: ml4.delay,
    })
    .add({
      targets: ".ml4 .letters-3",
      opacity: ml4.opacityIn,
      scale: ml4.scaleIn,
      duration: ml4.durationIn,
    })
    .add({
      targets: ".ml4 .letters-3",
      opacity: 0,
      scale: ml4.scaleOut,
      duration: ml4.durationOut,
      easing: "easeInExpo",
      delay: ml4.delay,
    })
    .add({
      targets: ".ml4",
      opacity: 0,
      duration: 500,
      delay: 500,
    });
  return (
    <div>
      <div className="home-container wrapper bg-image d-flex align-items-center justify-content-center bg-image d-block">
        <h1 className="text-center title-margin text-white">
          <div className="display-4 font-weight-bold">
            PARAS <span className="text-green">PATEL</span>
          </div>
          <div className="mt-2 mb-4">
            <a
              href="https://www.linkedin.com/in/paras-j-patel/"
              target="_blank"
              className="text-white"
              rel="noreferrer"
            >
              <i className="bi bi-linkedin h2"></i>
            </a>
            <a
              href="https://github.com/paparasj/"
              target="_blank"
              className="text-white"
              rel="noreferrer"
            >
              <i className="bi bi-github h2 mr-3 ml-3"></i>
            </a>

            <a
              href="mailto:patelpasu110@gmail.com?subject=From Portfolio&body=Hello Paras,"
              className="text-white"
              rel="noreferrer"
            >
              <i className="bi bi-envelope-fill h2"></i>
            </a>
          </div>

          <p className="text-white h5 text-center font-italic mt-2 space-letter">
          "Driven <strong>Software Development</strong> with a passion for solving complex problems and creating innovative solutions."
            {/* "Looking for an opportunity to enhance my knowledge in{" "}
            <strong>Software Development</strong>" */}
          </p>
         {/* <h1 class="ml4">
            <span class="letters letters-1">I am Back End Developer</span>
            <span class="letters letters-2">I am Full Stack Developer</span>
            <span class="letters letters-3">I am coder.</span>
          </h1> */}
        </h1>
      </div>
    </div>
  );
};

export default Home;
