import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../css/Skills.css";
import "../../node_modules/aos/dist/aos.js";
import "../../node_modules/aos/dist/aos.css";
import AOS from "aos";

const Skills = () => {
  AOS.init();
  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="1000"
    >
      <h2 className="text-center heading-responsive font-weight-bold h1">
        Skills
      </h2>
      <br></br>
      <br></br>
      <div className="width-component1 h-auto ml-auto mr-auto h4 mb-5">
        <div className="row  text-muted">
          <div className="col-lg bg-light-grey rounded">
            <h3 className="text-dark font-weight-bold">
              Programming Languages:{" "}
            </h3>
            <li>Java</li>
            <li>C#</li>
            <li>JavaScript</li>
            <li>PHP</li>
            <li>Rest Web APIs</li>
            <li>HTML</li>
            
            <li>SQL</li>
          </div>
          <div className="col-lg bg-light-grey rounded">
            <h3 className="text-dark font-weight-bold">
              Frameworks & Libraries:{" "}
            </h3>
            <li>React</li>
            <li>Angular</li>
            <li>jQuery</li>
            <li>Node.js</li>
            <li>Bootstrap</li>
            <li>.NET Frameworks</li>
          </div>
          <div className="col-lg bg-light-grey rounded">
            <h3 className="text-dark font-weight-bold">Software & Tools: </h3>
            <li>VS Code</li>
            <li>Eclipse</li>
            <li>Oracle SQL Developer</li>
            <li>NetBeans</li>
            <li>Talend</li>
            <li>Visual Paradigm</li>
            <li>Git</li>
            <li>MS Office</li>
            
          </div>
          <div className="col-lg bg-light-grey rounded">
            <h3 className="text-dark font-weight-bold">Soft Skills: </h3>
            <li>Leadership</li>
            <li>Teamwork</li>
            <li>Fast Learner</li>
            <li>Problem Solving</li>
            <li>Communication</li>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Skills;
