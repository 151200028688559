import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

const Footer = () => {
  return (
    <div>
      <footer className="text-center bg-dark text-white m-0">
        <br></br>
        <p className="h1 font-weight-bold">Paras Patel</p>
        <p className="h5 font-weight-light">&copy; Copyright @2022</p>
        <div>
          <a
            href="https://www.linkedin.com/in/paras-j-patel/"
            target="_blank"
            className="text-white"
            rel="noopener noreferrer"
          >
            <i className="bi bi-linkedin h2 mr-3"></i>
          </a>
          <a
            href="https://github.com/paparasj/"
            target="_blank"
            className="text-white"
            rel="noopener noreferrer"
          >
            <i className="bi bi-github h2 mr-3"></i>
          </a>
          <a
            href="https://www.instagram.com/paras1378/"
            target="_blank"
            className="text-white"
            rel="noopener noreferrer"
          >
            <i className="bi bi-instagram h2 mr-3"></i>
          </a>
        </div>
        <br></br>
      </footer>
    </div>
  );
};

export default Footer;
