import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import ContactForm from "./ContactForm";

import "../../node_modules/aos/dist/aos.js";
import "../../node_modules/aos/dist/aos.css";

import AOS from "aos";

const Contact = () => {
  AOS.init();
  return (
    <div>
      <div
        className="vh-100"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1000"
      >
        <h2 className="text-center heading-responsive font-weight-bold h1">
          Contact Me
        </h2>
        <br></br>
        <br></br>
        <div className="text-center">
          <p className="h5 font-italic text-muted">
            Hello Everyone 👋<br></br>You can reach me through Email or Call Me.
            I will be Happy to talk with you.
          </p>
          <br></br>
          <div className="row contact-button-width mr-auto ml-auto">
            <div className="col-xl  pt-5 pb-5 pl-0 pr-0  rounded">
              <a href="tel:6474463591">
                <i className="bi bi-telephone-inbound bg-primary h1 rounded-circle text-white  p-3 pl-4 pr-4"></i>
              </a>
              <p className="h5 mt-4 ">647-446-3591</p>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="col-xl  pt-5 pb-5 pl-0 pr-0  rounded">
              <a href="mailto:patelpasu110@gmail.com?subject=From Portfolio&body=Hello Paras,">
                <i className="bi bi-envelope-fill bg-primary h1 rounded-circle  text-white p-3 pl-4 pr-4"></i>
              </a>
              <p className="h5 mt-4 ">patelpasu110@gmail.com</p>
            </div>
          </div>
          <br></br>
          <ContactForm></ContactForm>
        </div>
      </div>
    </div>
  );
};

export default Contact;
