import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import emailjs from "emailjs-com";
import Footer from "./Footer";

const ContactForm = () => {
  const SendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        `service_paparasj`,
        `template_paparasj`,
        e.target,
        `user_vudZZeLOcIPNCryjLjJg6`
      )
      .then(
        (result) => {
          alert("Message is sent Successfully", result.text);
          document.getElementById("contact-form-reset").reset();
        },
        (error) => {
          alert("An error occurred, Please try again later!", error.text);
        }
      );
  };

  return (
    <div>
      <div className="p-4 shadow shadow-color rounded contact-button-width ml-auto mr-auto h5  border-content">
        <h2 className="h3">Have a Question?</h2>
        <br></br>
        <form id="contact-form-reset" onSubmit={SendEmail}>
          <table className="ml-auto mr-auto width-component1">
            <tbody>
              <tr>
                <td className="text-left">
                  <label>Name</label>
                </td>
                <td className="input-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your First Name"
                    className="form-control"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <label>Email</label>
                </td>
                <td className="input-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your Email"
                    className="form-control"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <label>Subject</label>
                </td>
                <td className="input-group">
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    className="form-control"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <label>Message</label>
                </td>
                <td className="input-group">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Let's talk!"
                    className="form-control text-area"
                    aria-label="With textarea"
                    rows="4"
                    cols="50"
                    required
                  ></textarea>
                </td>
              </tr>
              &nbsp;
              <tr>
                <td colSpan="2">
                  <input
                    type="submit"
                    value="Send"
                    className="text-white bg-primary p-2 pl-3 pr-3 shadow font-weight-bold"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <br></br>
      <Footer></Footer>
    </div>
  );
};

export default ContactForm;
