import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../components/Work";
import "../../node_modules/aos/dist/aos.js";
import "../../node_modules/aos/dist/aos.css";
import AOS from "aos";

const Education = () => {
  AOS.init();
  return (
    <div>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1000"
      >
        <h2 className="text-center heading-responsive font-weight-bold h1">
          Education
        </h2>
        <br></br>
        <br></br>
        <div className="text-center width-component ml-auto mr-auto">
          <div>
            <p className="text-muted font-weight-bold text-left h4">
              Jan 2020 - Apr 2021
            </p>
            <h2 className="text-left font-weight-bold h3">
              Computer Programming - College Diploma
            </h2>
            <h3 className="text-muted h4 text-left">
              Sheridan Institute of Applied Arts & Technology
            </h3>
            <p className="text-left h5">Graduated with High Honours: GPA - 3.92 / 4.00</p>
            <div className="blockquote text-left">
              <ul>
                <li>Software Development in Java, C# and .NET.</li>
                <li>Web technologies and Web Application Development.</li>
                <li>Database Technologies and Database Administration.</li>
              </ul>
            </div>
          </div>
          &nbsp;
          <hr className="bg-dark"></hr>
          &nbsp;
          <div className="">
            <p className="text-muted font-weight-bold text-left h4">
             July 2014 - May 2018
            </p>
            <h2 className="text-left font-weight-bold h3">
            Information Technology - Bachelor of Engineering
            </h2>
            <h3 className="text-muted h4 text-left">
            Gujarat Technological University, India
            </h3>
            <p className="text-left h5">Graduated with First Class with Distinction: CGPA - 7.97/10.00</p>
            <div className="blockquote text-left">
              <ul>
              <li>Learned Computer Science fundamentals</li>
            <li>Analysis and Design of Algorithms</li>
            <li>Software Development using C, C++, JAVA, C#, and .Net </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
